/* TrasladoDetalle.css */

.traslado-detalle-container {
    background-color: #3f3e3e;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff; /* Reemplaza con tu color preferido */
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Sombra suave para dar profundidad */
  }
  
  .back-button {
    background-color: #FFFFFF; /* Fondo blanco para el botón */
    border: none;
    padding: 8px 16px;
    border-radius: 50%; /* Botón circular */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Sombra para darle un aspecto elevado */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s; /* Transición suave para el efecto de hover */
  }

  .back-button:hover {
    background-color: #4fdf13; /* Color al hacer hover */
  }
  
  .contenido {
    padding: 16px;
    background-color: white;
  }
  
  .information-block {
    margin-bottom: 16px;
  }
  
  .paper {
    margin: 8px 0;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); /* Sombra suave para los papeles */
  }
  
  .block-title {
    color: #4fdf13; /* Color del título para hacerlo destacar */
    margin-bottom: 8px;
  }
  
  .data-block {
    background-color: white;
    margin-bottom: 10px;
    border-radius: 4px; /* Bordes redondeados para un look moderno */
    box-shadow: 0 2px 4px rgba(0,0,0,0.05); /* Sombra suave */
  }
  
  /* Estilos para los iconos de título, si los añades */
  .icon-title {
    vertical-align: middle;
    margin-right: 8px;
  }
  
/* Estilos para el texto de Traslado ID */
.traslado-id-text {
    color: #2d461e; /* Texto blanco para contraste */
    font-weight: bold; /* Fuente en negrita */
  }
  
  .traslado-detalles .detalles-title {
    color: #4fdf13;
    background-color: #3f3e3e; /* o el color exacto del fondo que necesites */
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .traslado-detalles .detalles-lista {
    background-color: #3f3e3e; /* o el color exacto del fondo que necesites */
    border-radius: 10px;
    padding: 10px;
  }
  
  .traslado-detalles .detalle-item {
    background-color: #ffffff; /* o el color exacto del fondo para el item */
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .traslado-detalles .detalle-fecha {
    font-weight: bold;
    margin-right: 10px;
  }
  
