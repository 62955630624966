.traslados-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los items de traslado */
    padding: 20px;
  }

  .traslados-item.cancelado {
    background-color: #ee1325;
    position: relative;
    border: 1px solid #e0e0e0; /* Borde sutil */
    border-radius: 8px; /* Bordes más redondeados para un look moderno */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Sombra más pronunciada para profundidad */
    margin-bottom: 20px; /* Más espacio entre items */
    padding: 20px; /* Más espacio interno */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacio entre los elementos internos */
    font-family: 'Arial', sans-serif; /* Fuente más moderna */
  }
  
  .traslados-header {
    width: 100%;
    max-width: 720px; /* Define un ancho máximo para el encabezado */
    margin-bottom: 20px;
    /* Resto de tu CSS para el header */
  }
  
  .traslados-list {
    width: 100%;
    max-width: 900px; /* Define un ancho máximo para la lista de items */
  }

  .traslados-item {
    position: relative;
    background-color: white; /* Blanco para limpieza visual */
    border: 1px solid #e0e0e0; /* Borde sutil */
    border-radius: 8px; /* Bordes más redondeados para un look moderno */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Sombra más pronunciada para profundidad */
    margin-bottom: 20px; /* Más espacio entre items */
    padding: 20px; /* Más espacio interno */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacio entre los elementos internos */
    font-family: 'Arial', sans-serif; /* Fuente más moderna */
  }

  /* Utiliza media queries para ajustar el diseño en pantallas más pequeñas */
@media (max-width: 720px) {
    .traslados-header,
    .traslados-list {
      max-width: 100%;
    }
  
    .traslados-item {
      flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    }
  }
  
  .traslados-item-id {
    position: absolute;
    top: -1px; /* Alineación con el borde superior */
    left: -1px; /* Alineación con el borde izquierdo */
    background-color: #6200ea; /* Color más vibrante */
    color: white;
    padding: 5px 15px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px; /* Agregamos borde en la parte inferior derecha para un toque moderno */
    font-weight: 600; /* Fuente más pesada para legibilidad */
    font-size: 0.9em; /* Tamaño de fuente ajustado */
  }
  
  .traslados-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Alineación vertical de los elementos */
    font-size: 1.1em; /* Tamaño de fuente más grande para la cabecera */
  }
  
  .traslados-item-time, .traslados-item-typeD, .traslados-item-type {
    background-color: white; /* Color más suave para el fondo de los tags */
    color: #333333; /* Color de texto más oscuro para contraste */
    padding: 10px 15px;
    border-radius: 30px; /* Bordes extremadamente redondeados para los tags */
    font-size: 0.8em; /* Reducimos el tamaño de la fuente para los tags */
    font-family: sans-serif; /* Fuente más llamativa para los tags */ 
}
  
  .traslados-item-user-info {
    text-align: right;
    font-size: 0.9em; /* Tamaño de fuente ligeramente más pequeño para la info del usuario */
    font-family: sans-serif; /* Fuente más clásica para la info del usuario */
    border-radius: 30px;
    background-color: white;  
}
  
  .traslados-item-origin-destination {
    text-align: center;
    border: dashed;
    font-size: 0.9em; /* Tamaño de fuente para los detalles del viaje */
    background-color: white;
  }

  .export-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el botón y la alerta horizontalmente */
    margin-bottom: 20px; /* Espacio debajo del contenedor para separarlo de otros elementos */
  }
  
  .export-button {
    /* Tus estilos existentes para el botón */
    margin-bottom: 10px; /* Espacio debajo del botón */
  }
  
  .export-alert-container {
    background-color: #ffffff; /* Fondo blanco para la alerta */
    border-radius: 4px; /* Bordes redondeados para la alerta */
    padding: 10px; /* Espaciado interno para la alerta */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave para resaltar la alerta */
    width: 100%; /* La alerta ocupa el ancho del contenedor */
  }
  
  .export-alert {
    color: #333333; /* Color oscuro para el texto para mejorar la legibilidad */
    text-align: center; /* Centrar el texto de la alerta */
    margin: 0; /* Remover márgenes por defecto del párrafo */
    font-size: 0.9em; /* Tamaño ligeramente más pequeño para el texto */
  }
  
  .export-alert a {
    color: #007bff; /* Color para enlaces, usualmente un azul que indique interactividad */
    text-decoration: none; /* Opcional: remover el subrayado de los enlaces */
  }
  
  .export-alert a:hover {
    text-decoration: underline; /* Opcional: subrayar al pasar el mouse para mayor claridad */
  }
  
  
  .traslados-item-footerD, .traslados-item-footer {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Alineación vertical de los elementos */
  }
  
  .search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 400px; /* Ajusta según tus necesidades */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-size: 16px;
  }
  
  .search-input:focus {
    outline: none;
    border-color: #6200ea;
    box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
  }

  /* Estilos generales para los selectores y las etiquetas */
.date-selector {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espacio entre los selectores */
    margin-top: 20px;
  }
  
  .date-selector select {
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-size: 16px;
    background-color: white;
    cursor: pointer; /* Cambia el cursor para indicar que es interactivo */
  }
  
  .date-selector select:focus {
    outline: none;
    border-color: #6200ea;
    box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
  }
  
  .date-selector label {
    font-weight: bold;
    align-self: center;
    background-color: white;
}

.no-records-notification {
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffdddd; /* Un color de fondo suave */
    border-left: 5px solid #da0707; /* Una barra lateral para destacar */
    color: #333; /* Color de texto */
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  .loading-notification {
    text-align: center;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    color: #666;
    margin: 20px 0;
    animation: pulse 1s infinite ease-in-out;
  }
  
  /* Simple keyframe animation for pulse effect */
  @keyframes pulse {
    0% {
      background-color: #f2f2f2;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f2f2f2;
    }
  }
  
  
  .traslados-item:hover {
    background-color: #0ca14f; /* Cambia el color de fondo al pasar el ratón por encima */
    cursor: pointer; /* Cambia el cursor a pointer para indicar que es interactivo */
  }


  /* Asumiendo que el botón está dentro de un div con clase 'export-container' */
.export-container {
  display: flex;
  justify-content: center; /* Centra el botón horizontalmente */
  align-items: center; /* Centra el botón verticalmente (si el contenedor tiene altura definida) */
  margin-top: 20px; /* Margen superior para separarlo de otros elementos */
}

.export-button {
  background-color: #4CAF50; /* Color de fondo */
  color: white; /* Color del texto */
  padding: 10px 20px; /* Espaciado interno */
  margin: 20px 0; /* Margen exterior */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cursor en forma de mano al pasar el mouse */
  font-size: 16px; /* Tamaño del texto */
  transition: background-color 0.3s; /* Transición suave al cambiar de color */
}

.export-button:hover {
  background-color: #45a049; /* Cambio de color al pasar el mouse */
}

.status-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.status-filters div {
  display: flex;
  align-items: center;
}

.status-filters input[type="checkbox"] {
  margin-right: 5px;
}

/* Estilo para selectores de fecha y mes */
.date-selector {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.date-selector select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Botón de Exportar */
.export-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.export-button:hover {
  background-color: #0056b3;
}

.traslados-actions {
  display: flex;
  justify-content: space-between; /* Alinea los elementos a cada extremo */
  align-items: center; /* Centra los elementos verticalmente */
  margin-bottom: 20px; /* Margen para separar del resto del contenido */
}

.traslados-count {
  padding: 10px 20px;
  margin: 20px 0; /* Ajusta según tu layout */
  background-color: #007bff; /* Un azul suave */
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Sombra sutil */
  text-align: center;
}

.traslados-count h2 {
  margin: 0;
  font-size: 18px; /* Ajusta este valor según prefieras */
  font-weight: normal; /* Para que no sea tan grueso */
}

.load-button {
  padding: 10px 20px;
  background-color: #007bff; /* Color de fondo azul */
  color: white; /* Texto blanco */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 16px; /* Tamaño de texto */
  cursor: pointer; /* Cursor en forma de mano al pasar sobre el botón */
  margin-top: 20px; /* Margen superior */
  transition: background-color 0.3s; /* Transición suave del color de fondo */
}

.load-button:hover {
  background-color: #0056b3; /* Color de fondo más oscuro al pasar el cursor */
}

.load-button:disabled {
  background-color: #cccccc; /* Color de fondo para el estado desactivado */
  cursor: not-allowed; /* Cursor en forma de no permitido */
}
