/* App.css */
.App {
  text-align: center; /* Centra el contenido */
  margin: 20px 0; /* Espaciado superior e inferior */
}

.App h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Fuente moderna */
  font-size: 2.5rem; /* Tamaño de fuente grande */
  color: #4fdf13; /* Color oscuro para el texto */
  margin-bottom: 20px; /* Espaciado debajo del título */
  padding: 5px 0; /* Espaciado interno */
  border-bottom: 2px solid #d9e5f1; /* Borde inferior azul para destacar */
  display: inline-block; /* Ajusta el ancho al contenido */
  margin-bottom: 50px; /* Espacio antes del contenido */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Sombra suave para profundidad */
}

/* Ajustes para responsividad */
@media (max-width: 768px) {
  .App h1 {
    font-size: 2rem; /* Tamaño de fuente más pequeño para pantallas estrechas */
  }
}
